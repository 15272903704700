import { fetchJSON } from "#js/components/http"

let companyConfigCache

/**
 * Fetches the company configuration from the server and caches it.
 * @returns {Promise<object>} - The company's configuration.
 */
export async function fetchCompanyConfig() {
  if (companyConfigCache === undefined) {
    companyConfigCache = Object.freeze(await fetchJSON(globalThis.company.configUrl))
  }
  return companyConfigCache
}

let userConfigCache

/**
 * Fetches the user configuration from the server and caches it.
 * @returns {Promise<object>} - The user's configuration.
 */
export async function fetchUserConfig() {
  if (userConfigCache === undefined) {
    if (!globalThis.user.isAuthenticated) {
      throw new Error("User is not authenticated")
    }
    userConfigCache = Object.freeze(await fetchJSON(globalThis.user.configUrl))
    console.debug("fetching userConfig:", userConfigCache)
  }
  return userConfigCache
}

export function clearConfigCache() {
  companyConfigCache = undefined
  userConfigCache = undefined
}

export function mockCompanyConfig(config) {
  companyConfigCache = Object.freeze(config)
}

export function mockUserConfig(config) {
  userConfigCache = Object.freeze(config)
}
